import * as React from 'react';
import { ColorScheme } from '@zavy360/config/themes/ThemeProvider';
import { useEffect, useMemo } from '../react';

export default function useAppearance() {
  const [appearance, setAppearance] = React.useState<ColorScheme>(ColorScheme.Light);
  const toggleAppearance = React.useCallback(() => {
    setAppearance((current) => (current === ColorScheme.Light ? ColorScheme.Dark : ColorScheme.Light));
  }, []);

  useEffect(() => {
    global.window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      setAppearance(event.matches ? ColorScheme.Dark : ColorScheme.Light);
    });
  }, []);

  return useMemo(() => ({ appearance, toggleAppearance }), [appearance, toggleAppearance]);
}
