import * as React from 'react';
import { useCustomEqualityCheck } from './useCustomEqualityCheck';

// biome-ignore lint/suspicious/noExplicitAny: Allowed for type inference
export function useCallback<MemoizedFn extends (...args: any[]) => any, Dependencies extends React.DependencyList>(
  value: MemoizedFn,
  dependencies: Dependencies,
  isEqual?: (current: React.DependencyList, next: React.DependencyList) => boolean
): MemoizedFn {
  const deps = useCustomEqualityCheck(dependencies, isEqual);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(value, deps);
}

export function useAsyncCallback<
  // biome-ignore lint/suspicious/noExplicitAny: Allowed for type inference
  MemoizedFn extends (...args: any[]) => Promise<any>,
  Dependencies extends React.DependencyList
>(
  value: MemoizedFn,
  dependencies: Dependencies,
  isEqual?: (current: React.DependencyList, next: React.DependencyList) => boolean
) {
  const [loading, setLoading] = React.useState(false);
  const deps = useCustomEqualityCheck(dependencies, isEqual);
  // biome-ignore lint/correctness/useExhaustiveDependencies: Intentionally not specifying setLoading because its reference wont change, biome bug
  const memoized: MemoizedFn = React.useCallback(async (...args: Parameters<MemoizedFn>) => {
    try {
      setLoading(true);
      return await value(...args);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps) as MemoizedFn;

  return [memoized, loading] as [MemoizedFn, boolean];
}
