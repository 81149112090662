import clsx from 'clsx';
import type * as React from 'react';
import { useMemo } from '@zavy360/hooks/react';
import { createUseStyles } from 'react-jss';

function createAnimatedStyleHook({ height, divider }: ISlideProps) {
  return createUseStyles({
    '@keyframes slideDown': {
      from: {
        height: 0,
        minHeight: undefined,
        marginTop: divider ? 0 : undefined,
        paddingTop: divider ? 0 : undefined
      },
      to: {
        height: 'auto',
        minHeight: height,
        marginTop: divider ? 8 : undefined,
        paddingTop: divider ? 8 : undefined
      }
    },
    '@keyframes slideUp': {
      from: {
        height: 'auto',
        minHeight: height,
        marginTop: divider ? 8 : undefined,
        paddingTop: divider ? 8 : undefined
      },
      to: {
        height: 0,
        minHeight: undefined,
        marginTop: divider ? 0 : undefined,
        paddingTop: divider ? 0 : undefined
      }
    },
    container: {
      flex: 1,
      display: 'flex',
      height: 0,
      flexDirection: 'row',
      overflow: 'hidden'
    },
    slideDown: {
      minHeight: height,
      height: 'auto',
      marginTop: divider ? 8 : undefined,
      paddingTop: divider ? 8 : undefined,

      animationName: '$slideDown',
      animationIterationCount: 1,
      animationTimingFunction: 'ease-in',
      animationDuration: '0.3s'
    },
    slideUp: {
      height: 0,
      minHeight: undefined,
      animationName: '$slideUp',
      animationIterationCount: 1,
      animationTimingFunction: 'ease-in',
      animationDuration: '0.3s'
    }
  });
}
interface ISlideProps {
  visible?: boolean;
  divider?: boolean;
  height: number;
}
export default function Slide(props: React.PropsWithChildren<ISlideProps>) {
  const { visible, height, divider, children } = props;
  const useStyles = useMemo(() => createAnimatedStyleHook({ height, divider }), [height, divider]);
  const classes = useStyles();
  return <div className={clsx(classes.container, visible ? classes.slideDown : classes.slideUp)}>{children}</div>;
}
