import { Duration } from 'luxon';

interface ILexicalDebugConfig {
  // Base formatting, e.g bold/italic etc
  formatting: boolean;
  // Fine grained control
  tables: boolean;
  pageMargins: boolean;
  images: boolean;
  notes: boolean;
  variables: boolean;
  customEvents: boolean;
  snippets: boolean;
  contactInfo: boolean;
  content: boolean;

  // Debug base lexical editor, e.g onChange etc
  editor: boolean;

  // Debug utils, if debugging any plugin, this should
  // be set to true as well
  utils: boolean;
}

export interface IAppConfig {
  apollo: {
    debug: boolean;
    cache: {
      // How long to cache data for (in ms)
      ttl:
        | number
        | {
            default: number; // Default TTL globally
            connections: number; // TTL for connection types (can be same)
            rootQuery: number; // TTL for QueryType (root query), this will expire all queries
          };
    };
    http: {
      batch: {
        // Whether batching is enabled, otherwise use HttpLink (not BatchHttpLink)
        enabled: boolean;

        // Batch groups — batch operations together
        groups?: Record<string, string[]>;

        // How many requests to batch together (set to 0 to disable batching)
        max: number;
        // Operations to ignore batching for (name of Query or Mutation)
        operationBlacklist: string[];
      };
    };
    ignoreErrors: {
      // Ignore custom application errors, e.g SessionExpired
      custom: boolean;
      // Ignore network errors, e.g 500 responses, and allow processing
      // to continue with whatever data is returned
      network: boolean;
    };
    hooks: {
      // Providers created by @zavy360/graphql/crud
      providers: {
        debug: boolean;
      };
      // Print debug logging for subscriptions
      subscriptions: {
        debug: boolean;
      };
      // Lazy queries from useLazyQueryEffect in @zavy360/graphql/client/hooks
      lazyQueryEffect: {
        debug: boolean;
      };
    };
  };
  session: {
    debug: boolean;
  };

  lexical: {
    // Debug all lexical plugins, utils, hooks, etc
    debug: boolean | ILexicalDebugConfig;
  };
}

const config: IAppConfig = {
  apollo: {
    debug: true, // process.env.NODE_ENV !== 'production'
    cache: {
      ttl: Duration.fromObject({ minutes: 5 }).as('milliseconds')
    },
    ignoreErrors: {
      custom: true,
      network: false
    },
    http: {
      batch: {
        enabled: true,
        max: 8,
        // Default batch groups — rather than blacklisting operations,
        // you can group them together, or even group them to be on their own,
        // effectively not batching them at all since they're sent on their own.
        // Downside of that is that they will still take an additional 40ms (batch interval)
        // while the batch link waits for other queries to batch together, even if there arent any
        groups: {
          initialization: ['Session', 'Environment']
        },
        operationBlacklist: [
          'CalendarEvents',
          'CalendarRosterEvents',
          'OnHoldCalendarAppointments',
          'StaffRoster',
          'RefreshAuthToken',
          'Locations',
          'DashboardWidgets',
          'UserSearch',
          'UploadAttachments'
        ]
      }
    },
    hooks: {
      lazyQueryEffect: {
        debug: true
      },
      subscriptions: {
        debug: true
      },
      providers: {
        debug: true
      }
    }
  },
  session: {
    debug: true
  },
  lexical: {
    // Debug all lexical plugins, utils, hooks, etc
    debug: {
      formatting: true,
      utils: true,
      contactInfo: true,
      customEvents: true,
      editor: true,
      images: true,
      notes: true,
      pageMargins: true,
      snippets: true,
      tables: true,
      variables: true,
      content: true
    }
  }
};

export default config;
