import { useSwitchUserMutation } from '@zavy360/graphql/hooks';
import useSession from '@zavy360/graphql/session/useSession';
import { useCallback } from './react';

export default function useSwitchUser() {
  const { session } = useSession();
  const [switchUser, { client }] = useSwitchUserMutation();

  const switchUpc = useCallback(
    async (guid: string, force = false) => {
      if (session?.practiceConnection?.guid === guid && !force) {
        return;
      }
      try {
        const { data } = await switchUser({ variables: { upc: guid } });

        if (data?.switchUser?.session?.id) {
          // const { session } = data.switchUser;
          window.location.href = '/overview';
          return;
          /*
          if (isSubdomain(session.practiceConnection.account.subdomain)) {
            client.resetStore();
            // Immediately update the session here
            // so that there's no diff between the user
            // we switched to and the user in the session
            client.cache.updateFragment<SessionFragment>(
              {
                fragment: SessionFragmentDoc,
                fragmentName: 'session',
                canonizeResults: true,
                id: client.cache.identify(data.switchUser.session)
              },
              (prev) => data.switchUser.session
            );
          } else {
            redirectToSubdomain(session.practiceConnection.account.subdomain);
          }
          // redirectToSubdomain(credentials.userPracticeConnection.account.subdomain);
          */
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [session?.practiceConnection?.guid, switchUser]
  );

  return switchUpc;
}
