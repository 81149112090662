export default {
  // Theme colors
  primary: '#635bff',
  surface: '#fff',
  background: '#fafafa',
  background2: '#f6f6f6',
  link: '#1891ff',
  border: '#eeeeee',

  // Color palette
  black: '#000000',
  blue: '#0087FF',
  lightBlue: '#007AFF',
  teal: '#5ac8fa',
  lightTeal: 'red',
  indigo: '#635bff',
  orange: '#ff9500',
  pink: '#ff2d55',
  white: '#fff',
  superWhite: '#fafafa',
  green: '#34c759',
  red: '#ff3b30',
  faintRed: '#FEE8EC',
  lightRed: 'fff7f8',
  yellow: '#ffcc00',
  purple: '#af52de',
  faintGrey: '#F2F7FA',
  lightGrey: '#ADB8BE',
  darkGrey: '#464646',
  grey: '#979797',
  darkBorder: '#D9E2E8',
  greyBorder: '#D9E2E8',
  inputBorderGrey: '#ccc',
  secondaryNav: '#F0F3F7',
  formBg: '#f6fbff',
  faintBlue: '#E3F3FE',
  blizardBlue: 'a8def3',
  quartz: 'd2cff0',
  mauve: 'fcbcff',
  lavenderBlue: 'badaff',
  lightSkyBlue: '92f7f2',
  peach: 'ffcdb1',
  lightPink: 'ffb7bf',
  melon: 'ffb4b0',
  cottonCandy: 'ffb7d7',
  lightCyan: 'c3ffe0',
  mintGreen: 'b4ffad',
  gainsboro: 'e1e1e1',
  lemonChiffon: 'fffdba',
  moccasin: 'ffecbd',
  aquamarine: 'b4ffdb',
  violetRed: 'fc3267',
  fontAwesome: 'Font Awesome 5 Free',
  darkGrayishNavy: '#2E313B',
  dodgerBlue: '#0091FF',
  gray20: '#333',
  gray90: '#e5e5e5',
  whiteSmoke: '#f1f1f1',
  whiteGreyBlack: '#d5d5d5',
  lightGrayishBlue: '#6b778c',
  gray92: 'ebebeb',
  weakGrey: '#fcfcfd',
  blue_1: '#e6f7ff',
  blue_2: '#bae7ff',
  blue_3: '#91d5ff',
  blue_4: '#69c0ff'
};
