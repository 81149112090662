import { useMobileOrientation } from 'react-device-detect';
import { useWindowSize } from 'react-use';
import { useMemo } from '../react';

export enum ScreenOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape'
}

export default function usePlatform() {
  const { isLandscape } = useMobileOrientation();
  const { height, width } = useWindowSize();

  return useMemo(
    () => ({
      orientation: isLandscape ? ScreenOrientation.Landscape : ScreenOrientation.Portrait,
      dimensions: { height, width }
    }),
    [height, isLandscape, width]
  );
}
