import { useCallback } from '@zavy360/hooks/react';
import * as React from 'react';
import { Link, type LinkProps, useNavigate as useNavigation } from 'react-router-dom';
import { useSession } from '@zavy360/graphql/session';

export function useNavigate(): ReturnType<typeof useNavigation> {
  return useNavigation();
}

export default function PracticeLink(props: LinkProps) {
  const { to, ...rest } = props;
  const { session } = useSession();
  return <Link {...rest} {...{ to }} />;
}
