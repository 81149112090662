import * as React from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { SuspenseChunkLoadingSpinner } from '@zavy360/components/Navigation';
import { getBundleBasename } from '@zavy360/utils/webpack';
// import { lazyWithCatch } from '@zavy360/hooks/react';

// lazyWithCatch implements reloading, but disabling for now as we are trying a new cache policy
// on the servers first
const MainApp = React.lazy(() => import('./App'));
const PublicApp = React.lazy(() => import('../PublicApp'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='public/*' element={<PublicApp />} />
      <Route path='*' element={<MainApp />} />
    </>
  ),
  {
    basename: getBundleBasename()
  }
);
export default function Entrypoints() {
  return (
    <SuspenseChunkLoadingSpinner>
      <RouterProvider router={router} />
    </SuspenseChunkLoadingSpinner>
  );
}
