import { createStyles } from '@zavy360/config/themes';
import type * as React from 'react';

export interface IFadeProps {
  in: boolean;
  duration?: number;
  destroyOnHide?: boolean;
}
const useStyles = createStyles({
  '@keyframes fadeInOpacity': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  },
  '@keyframes fadeOutOpacity': {
    from: {
      opacity: 1
    },
    to: {
      opacity: 0
    }
  },
  fadeIn: ({ duration }: IFadeProps) => ({
    opacity: 1,
    animationName: '$fadeInOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: `${duration}s`,
    maxHeight: '100%',
    transition: `max-height ${duration}s ease-in`
  }),
  fadeOut: ({ duration }: IFadeProps) => ({
    opacity: 0,
    animationName: '$fadeOutOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: `${duration}s`,
    height: '0',
    overflow: 'hidden',
    transition: `max-height ${duration}s ease-out`
  })
});

export default function Fade(props: React.PropsWithChildren<IFadeProps>) {
  const { in: visible, duration = 400, destroyOnHide = false } = props;
  const { children } = props;
  const classes = useStyles({ in: visible, duration: duration / 1000 });
  return (
    <div className={visible ? classes.fadeIn : classes.fadeOut}>
      <div className='fadeInner'>{destroyOnHide ? (visible ? children : null) : children}</div>
    </div>
  );
}
