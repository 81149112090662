import { Modal, Progress } from 'antd';
import { Comment } from '@ant-design/compatible';
import { useCallback, useEffect, useMemo } from '../react';
import { noop } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';

interface IIdleDialogContentProps {
  start: DateTime;
  timeout: number;
}

function IdleDialogContent(props: IIdleDialogContentProps) {
  const { start, timeout } = props;
  const [percent, setPercent] = React.useState(0);
  const timer = React.useRef(null);

  const countDown = useCallback(() => {
    const millisecondsSinceStart = DateTime.now().diff(start).milliseconds;
    const newPercent = Math.round(100 / (timeout / millisecondsSinceStart));
    setPercent(newPercent);

    // Clear interval at 0
    if (newPercent < 0) {
      clearInterval(timer.current);
    }
  }, [start, timeout]);

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(countDown, 1000);
  }, [countDown]);

  return (
    <Comment
      avatar={
        <Progress
          type='circle'
          {...{ percent }}
          width={56}
          format={() => `${Math.round(start.plus({ milliseconds: timeout }).diffNow('seconds').seconds) || 0}`}
        />
      }
      content={<div>You&apos;ve been inactive for while and will soon be considered idle</div>}
    />
  );
}

interface IUseActivityOpts {
  // Defaults to 5 minutes
  secondsUntilIdle?: number;
  // Whether or not to show a prompt to the user, similar to TimeDoctor
  promptUser?: boolean;
}

export default function useActivity(opts: IUseActivityOpts) {
  const { secondsUntilIdle = 5 * 60, promptUser = true } = opts;
  const [idleTimestamp, setIdleTimestamp] = React.useState(null);

  const confirmationPrompt = React.useRef<ReturnType<typeof Modal.confirm>>(null);
  const onIdle = useCallback(() => {
    if (confirmationPrompt.current) {
      confirmationPrompt.current.destroy();
    }
    setIdleTimestamp(new Date().getTime());
  }, []);
  const onActive = useCallback(() => setIdleTimestamp(null), []);
  const onPrompt = useCallback(() => {
    if (confirmationPrompt.current) {
      confirmationPrompt.current.destroy();
    }
    confirmationPrompt.current = Modal.confirm({
      title: 'Are you still there?',
      content: <IdleDialogContent timeout={60 * 1000} start={DateTime.now()} />,
      onOk: onActive,
      onCancel: noop
    });
  }, [onActive]);

  useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    // Wait 5 minutes (default) before considering the user idle
    timeout: secondsUntilIdle * 1000,

    // Prompt the user to confirm whether or not they're idle,
    // and if no action is taken after 60 seconds, consider them idle
    promptTimeout: promptUser ? 60 * 1000 : 0,
    crossTab: true,
    debounce: 500,
    leaderElection: true,
    syncTimers: 30000 // Sync timers every 30 seconds
  });

  return useMemo(
    () => ({
      isIdle: idleTimestamp !== null,
      secondsSinceActivity: idleTimestamp ? DateTime.fromMillis(idleTimestamp).diffNow('seconds').seconds : null,
      idleTimestamp: idleTimestamp ? DateTime.fromMillis(idleTimestamp) : null
    }),
    [idleTimestamp]
  );
}
