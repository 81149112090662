import * as React from 'react';
// import { useEffect } from '@zavy360/hooks/react';
// import { useTimeout } from 'react-use';

export function lazyWithCatch(importFunc, fallbackComponent = null) {
  return React.lazy(() =>
    importFunc().catch((error) => {
      console.error('lazyWithCatch error caught', error);

      // FIXME: RM this is not the best way to handle this. Need to find a proper solution
      if (error.name === 'ChunkLoadError') {
        console.debug('ChunkLoadError caught, reloading page');
        setTimeout(() => window.location.reload(), 3000);
      }

      return {
        default: () => {
          if (fallbackComponent) {
            return fallbackComponent;
          }

          return (
            <div>
              <p>We detected that you're running an older version of zavy. Please wait while we reload</p>
              {/* <p>{error.name}</p>
              <p>{error.message}</p> */}
            </div>
          );
        }
      };
    })
  );
}
