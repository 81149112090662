import { createStyles } from '@zavy360/config/themes';
import type * as React from 'react';

interface IResultsWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const useStyles = createStyles({
  resultsWrapper: {
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});
const ResultsWrapper: React.FunctionComponent<IResultsWrapperProps> = (props) => {
  const classes = useStyles();
  return <div className={classes.resultsWrapper} {...props} />;
};

export default ResultsWrapper;
