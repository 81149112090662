import { createContext } from '@zavy360/hooks/createContext';
import { useCallback, useEffect, useState } from '@zavy360/hooks/react';
import { useId, useRef } from 'react';
import { type BlockerFunction, useBlocker } from 'react-router-dom';

function useUnifiedBlocker() {
  // Every blocker that is registered is registered with an ID
  // of the caller. This lets us return the blocking state to
  // the caller only
  const [blockingId, setBlockingId] = useState<string | null>(null);
  const blockers = useRef<Record<string, BlockerFunction>>({});

  const blocker = useBlocker((tx) => {
    for (const id in blockers.current) {
      // console.debug('blocker called for id', id, blockers.current?.[id]?.(tx));
      if (blockers.current?.[id]?.(tx)) {
        setBlockingId(id);
        return true;
      }
    }
  });

  const registerBlocker = useCallback((id: string, blocker: BlockerFunction) => {
    blockers.current[id] = blocker;
    // Return unregister function
    return () => {
      delete blockers.current[id];
    };
  }, []);

  return { blockingId, blocker, registerBlocker };
}

const { Provider: NavigationBlockerContextProvider, useContext: useNavigationBlockerContext } = createContext(
  useUnifiedBlocker,
  {
    blocker: null,
    blockingId: null,
    registerBlocker: () => null
  }
);

export function useNavigationBlocker(fn: BlockerFunction) {
  const id = useId();
  const { registerBlocker, blockingId, blocker } = useNavigationBlockerContext();

  // Register and unregister the blocker
  useEffect(() => registerBlocker(id, fn), [fn, id, registerBlocker]);

  // Return the blocking state if the blocker is the one that is blocking
  return blockingId === id ? blocker : null;
}

export { NavigationBlockerContextProvider, useNavigationBlockerContext };
