import { fastDeepEqual } from '@zavy360/utils/equality';
import React, { memo } from 'react';
import { useMemo } from './react';

/**
 * Takes any hook and turns it into context / provider pattern
 * allowing children in the tree to access the hook's value
 * We do this a lot, so this saves the boilerplate
 */
export function createContext<Arguments, Value>(useHook: (args: Arguments) => Value, initial: Value) {
  const Context = React.createContext<Value>(initial);

  function Provider(props: React.PropsWithChildren<Arguments>) {
    const { children, ...params } = props;
    const opts = useMemo(() => params, [params]);
    const value = useHook(opts as Arguments);

    return <Context.Provider {...{ value }}>{children}</Context.Provider>;
  }

  function useContext() {
    return React.useContext(Context);
  }

  return { Provider: memo(Provider, fastDeepEqual), useContext };
}
