import { useMemo } from '@zavy360/hooks/react';
import {
  ThemeProvider as JssThemeProvider,
  JssProvider as ReactJssProvider,
  createGenerateId,
  createUseStyles,
  useTheme as useJssTheme,
  type Styles
} from 'react-jss';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import colors from './themes/colors';
import lightTheme from './themes/light';

export enum ColorScheme {
  Dark = 'dark',
  Light = 'light'
}

const generateId = createGenerateId({
  minify: true
});

export interface IThemeProviderProps {
  children: JSX.Element | JSX.Element[];
  classNamePrefix?: string;
}

const styledTheme = { colors };

export type JssTheme = typeof lightTheme;

export function useTheme() {
  return useJssTheme<typeof lightTheme>();
}
export function createStyles<C extends string, Props = unknown, Theme extends JssTheme = JssTheme>(
  styles: Styles<C, Props, Theme> | ((theme: Theme) => Styles<C, Props, undefined>)
) {
  const useStylesHook = createUseStyles<C, Props, Theme>(styles);
  return function useStyles(props?: Props) {
    const memoizedProps = useMemo(() => props, [props]);
    return useStylesHook(memoizedProps);
  };
}

// Styles created with this will never change class name, and will
// always have the class name specified as the key of that object
export function createStaticStyles<C extends string, Props = unknown, Theme extends JssTheme = JssTheme>(
  // This is required to prevent collisions
  classNamePrefix: string,
  styles: Styles<C, Props, Theme> | ((theme: Theme) => Styles<C, Props, undefined>)
) {
  const useStylesHook = createUseStyles<C, Props, Theme>(styles, {
    generateId: (rule) => `${classNamePrefix}-${rule.key}`
  });
  return function useStyles(props?: Props) {
    const memoizedProps = useMemo(() => props, [props]);
    return useStylesHook(memoizedProps);
  };
}

export const useGlobalStyles = createStaticStyles('global', {
  '@global': {
    'html, body': {
      margin: 0
    },
    '.site-content-padding': {
      padding: 24
    }
  }
});

export function GlobalStyles() {
  useGlobalStyles();
  return null as JSX.Element;
}

export function JssProvider({ children, classNamePrefix }: IThemeProviderProps) {
  return (
    <ReactJssProvider {...{ generateId, classNamePrefix }}>
      <JssThemeProvider theme={lightTheme}>{children}</JssThemeProvider>
    </ReactJssProvider>
  );
}

function ThemeProvider({ children, classNamePrefix }: IThemeProviderProps) {
  return (
    <JssProvider {...{ classNamePrefix }}>
      <StyledThemeProvider theme={styledTheme}>{children}</StyledThemeProvider>
      <GlobalStyles key='global' />
    </JssProvider>
  );
}

export default ThemeProvider;
