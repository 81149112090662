/* eslint-disable global-require */
import { createStyles } from '@zavy360/config/themes';
import { useCallback, useEffect, useMemo } from '@zavy360/hooks/react';
import Lottie, { type LottieOptions, type LottieRef } from 'lottie-react';
import * as React from 'react';
import { clsx } from 'clsx';
import { type TSize, getSize } from '../utils/getSize';

export enum LottieAnimation {
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Spinner = require('./lottie/spinner.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  SpinnerAlt = require('./lottie/spinner-2.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Loading = require('./lottie/loading.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  LoadingAlt = require('./lottie/loading2.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  LoadingChart = require('./lottie/chart-loading.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Doctor = require('./lottie/107925-doctor.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Medicine = require('./lottie/94896-medicine.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Register = require('./lottie/42476-register.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  ErrorWater = require('./lottie/error-water.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  ErrorWoman = require('./lottie/error-woman.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Images = require('./lottie/images.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Email = require('./lottie/email.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Notes = require('./lottie/notes.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  InvoicePatients = require('./lottie/invoice-patients.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  Templates = require('./lottie/templates.json'),
  // biome-ignore lint/style/useLiteralEnumMembers: these are static and dont change
  OnlineBookings = require('./lottie/online-bookings.json')
}

interface IAnimationProps {
  json: ReturnType<typeof require>;
  className?: string;
  size?: TSize;
  variant?: 'loop' | 'intermittent' | 'once';
  onComplete?(): void;
  onStart?(): void;
  onCompleteLoop?(): void;
}

// Generate a class for each size in the default size map

const useStyles = createStyles({
  container: ({ size = 'small' }: Pick<IAnimationProps, 'size'>) => ({
    height: getSize(size)
  })
});

export function Animation(props: IAnimationProps) {
  const { json, size, variant = 'loop', className, onCompleteLoop, onStart } = props;

  const ref: LottieRef = React.useRef(null);
  const animationTimer = React.useRef<ReturnType<typeof setInterval>>(null);

  const options: LottieOptions = useMemo(
    () => ({
      animationData: json,
      autoplay: true,
      loop: variant === 'loop'
    }),
    [json, variant]
  );

  const onAnimationStart = useCallback(() => {
    onStart?.();
  }, [onStart]);

  const onAnimationLoopEnd = useCallback(() => {
    onCompleteLoop?.();
  }, [onCompleteLoop]);

  const classes = useStyles({ size });

  useEffect(() => {
    if (variant !== 'intermittent') return;
    if (!ref.current) return;
    if (animationTimer.current) clearInterval(animationTimer.current);
    animationTimer.current = setInterval(() => {
      // Go to the first frame, and play once
      ref.current?.goToAndPlay(0);
    }, 2000);
  }, [variant]);

  return (
    <Lottie
      className={clsx(classes.container, className)}
      {...options}
      onSegmentStart={onAnimationStart}
      onLoopComplete={onAnimationLoopEnd}
      animationData={options.animationData}
      lottieRef={ref}
    />
  );
}
