import * as React from 'react';
import { createStyles } from '@zavy360/config/themes';
import { Animation, LottieAnimation } from '@zavy360/ui/Animation';
import type { TSize } from '@zavy360/ui/utils';

const useStyles = createStyles({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100vh'
  },
  transparentContainer: {
    position: 'absolute',
    top: 0,
    backgroundColor: '#d9d9d9',
    width: '100%',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    opacity: ({ opacity }: IFullscreenSpinnerProps) => opacity
  }
});

export interface IFullscreenSpinnerProps {
  opacity?: number;
  size?: TSize;
}

export default function FullScreenSpinner(props: IFullscreenSpinnerProps) {
  const classes = useStyles(props);
  const { opacity, size = 'large' } = props;
  return (
    <div className={opacity ? classes.transparentContainer : classes.container}>
      <Animation {...{ size }} json={LottieAnimation.LoadingAlt} />
    </div>
  );
}
