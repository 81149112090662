import { useMemo } from '../react';
import usePlatform from './usePlatform';
import useScreen from './useScreen';
import useAppearance from './useAppearance';

export default function useDevice() {
  const platform = usePlatform();
  const screen = useScreen();
  const appearance = useAppearance();

  return useMemo(() => ({ platform, screen, appearance }), [appearance, platform, screen]);
}
