import { blue, green, red, orange } from '@ant-design/colors';
import { isMobile } from 'react-device-detect';
import colors from './colors';
import type { ButtonProps, RowProps } from 'antd';
import type { CardSize } from 'antd/lib/card/Card';

// Most of this is copied from light-theme.less
export default {
  palette: colors,
  colors: {
    primary: {
      main: colors.primary,
      mid: '#d7d9ff',
      light: '#f3f0ff',
      dark: '#7856ff'
    },
    grey: {
      lightest: '#fafafa',
      lighter: '#f5f5f5',
      light: '##f0f0f0',
      mid: '#d9d9d9'
    },
    secondary: {
      main: colors.lightBlue
    },
    background: {
      body: colors.background,
      surface: colors.surface,
      menuDark: '#1d143e'
    },
    text: {
      main: 'rgba(0, 0, 0, 0.65)',
      light: 'rgba(50, 50, 50, 0.65)',
      dark: 'rgba(0, 0, 0, 0.85)',
      link: colors.link,
      heading: 'rgba(0,0,0, 0.85)',
      selection: colors.primary
    },
    danger: {
      main: colors.red,
      light: colors.faintRed
    },
    status: {
      success: green[5],
      warning: orange[5],
      error: red[5],
      info: blue[5]
    }
  },
  borders: {
    radius: 6,
    color: colors.border,
    width: 1,
    style: 'solid'
  },
  text: {
    fontFamily: 'Apercu Pro, Helvetica Neue, Helvetica, Tahoma, Geneva, Arial, sans-serif',
    lineHeight: 1.5715,
    fontSize: {
      normal: 14,
      small: 12,
      h1: 14 * 2.71,
      h2: 14 * 2.14,
      h3: 14 * 1.71,
      h4: 14 * 1.42,
      h5: 14 * 1.14
    }
  },
  padding: {
    medium: {
      vertical: (16 * 3) / 4,
      horizontal: 16 * 2
    },
    small: {
      vertical: 16 / 2,
      horizontal: 16 / 2
    },
    main: {
      vertical: 24,
      horizontal: 24
    },
    lg: 32
  },
  components: {
    avatar: {
      size: isMobile ? 18 : 28
    },
    button: {
      size: isMobile ? 'small' : ('middle' as ButtonProps['size'])
    },
    card: {
      size: (isMobile ? 'small' : 'default') as CardSize,
      bordered: !isMobile
    },
    divider: {
      orientation: isMobile ? 'center' : 'left'
    },
    drawer: {
      width: isMobile ? '100%' : 700
    },
    row: {
      defaults: {
        gutter: [
          {
            xs: 8,
            md: 16,
            lg: 24,
            xl: 24
          },
          {
            xs: 8,
            md: 16,
            lg: 24,
            xl: 24
          }
        ] as RowProps['gutter']
      },
      gutter: {
        vertical: {
          xs: 8,
          md: 16,
          lg: 24,
          xl: 24
        },
        horizontal: {
          xs: 8,
          md: 16,
          lg: 24,
          xl: 24
        }
      }
    },
    topMenu: {
      height: 32,
      background: '#1d133e',
      // borderRadius: ,
      color: '#ffffff90',
      fontSize: 12,
      padding: '0px 32px 0px 16px'
    },
    pageHeader: {
      primary: {
        height: 54,
        radius: '4px 0 0 0'
      },
      secondary: {
        height: 54
      }
    },
    typography: {
      title: {
        level: isMobile ? 5 : 3
      }
    },
    space: {
      size: isMobile ? 2 : 4
    },
    table: {
      size: isMobile ? 'small' : 'middle'
    },
    tabs: {
      size: isMobile ? 'small' : undefined
    },
    descriptions: {
      size: 'small',
      label: {
        color: '#8f8f8f',
        width: 180
      }
    }
  }
};
