/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Required for webpack to inject chunks from the same host
// as Rails.configuration.asset_host
// See application.slim for the definition of this window-variable
import { getBundleUrl } from '@zavy360/utils/webpack';
if (window.assetHost) {
  __webpack_public_path__ = `${window.assetHost}/packs/`;
}
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from '../apps/src/MainApp';

// This is disabled until we can verify this works with antd
// to allow us to still upgrade to react 18
const CONCURRENT_MODE = true;

document.addEventListener('DOMContentLoaded', () => {
  if (CONCURRENT_MODE) {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<App />);
  } else {
    ReactDOM.render(<App />, document.getElementById('root'));
  }
});
