import { useEffect } from '@zavy360/hooks/react';
import { useRef, useState } from 'react';

/**
 * Hook that returns the visibility status of the current tab,
 * and allows a grace period to be set in milliseconds, where
 * if the tab is switched back to visible within that period,
 * the status will remain 'visible'
 */

export function useTabVisibility() {
  // Same thing but as a ref, doesnt force a re-render
  const ref = useRef<'visible' | 'hidden'>('visible');
  const [status, setStatus] = useState<'visible' | 'hidden'>('visible');

  useEffect(() => {
    const handleVisibilityChange = () => {
      ref.current = document.visibilityState;
      setStatus(document.visibilityState);
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return [status, ref] as const;
}
