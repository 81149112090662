import type Appsignal from '@appsignal/javascript';
import noop from 'lodash/noop';
import * as React from 'react';

export interface IAppSignalDefaultTags {
  upc: string;
  subdomain: string;
  practice: string;
  name: string;
  version: string;
  commit: string;
}
export interface IAppSignalContext {
  appSignal: Appsignal | undefined;
  apiKey: string;
  tags: { [key: string]: string } & IAppSignalDefaultTags;
  setApiKey(apiKey: string): void;
  setTags(tags: Partial<IAppSignalDefaultTags> | { [tagName: string]: string }): void;
}

export const INITIAL_TAGS = {
  upc: 'unknown',
  subdomain: 'unknown',
  practice: 'unknown',
  name: 'unknown',
  version: 'unknown',
  commit: 'unknown'
};

export const INITIAL_CONTEXT: IAppSignalContext = {
  appSignal: undefined,
  apiKey: null,
  tags: INITIAL_TAGS,
  setTags: noop,
  setApiKey: noop
};

const AppSignalContext = React.createContext(INITIAL_CONTEXT);

export function useAppSignalContext() {
  return React.useContext(AppSignalContext);
}

export default AppSignalContext;
