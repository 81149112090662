import { useMemo } from '../react';
import { type BrowserTypes, type OsTypes, useDeviceSelectors } from 'react-device-detect';

interface IDeviceSelectors {
  isMobile: boolean; // returns true if device type is mobile or tablet
  isMobileOnly: boolean; // returns true if device type is mobile
  isTablet: boolean; // returns true if device type is tablet
  isBrowser(legacy): boolean; // returns true if device type is browser (better to use isDesktop instead)
  isDesktop: boolean; // returns true if device type is browser (an alias of the isBrowser type
  isSmartTV: boolean; // returns true if device type is smarttv
  isWearable: boolean; // returns true if device type is wearable
  isConsole: boolean; // returns true if device type is console
  isEmbedded: boolean; // returns true if device type is embedded
  isAndroid: boolean; // returns true if os type is Android
  isWinPhone: boolean; // returns true if os type is Windows Phone
  isIOS: boolean; // returns true if os type is iOS
  isChrome: boolean; // returns true if browser is Chrome
  isFirefox: boolean; // returns true if browser is Firefox
  isSafari: boolean; // returns true if browser is Safari
  isOpera: boolean; // returns true if browser is Opera
  isIE: boolean; // returns true if browser is Internet Explorer
  isEdge: boolean; // returns true if browser is Edge or Edge Chromium
  isYandex: boolean; // returns true if browser is Yandex
  isChromium: boolean; // returns true if browser is Chromium
  isMobileSafari: boolean; // returns true if browser is Mobile Safari
  isSamsungBrowser: boolean; // returns true if browser is Samsung Browser
  osVersion: string; // returns os version (e.g 7 for Windows or 6 for Android)
  osName: string; // returns os name (e.g Windows, Android)
  fullBrowserVersion: string; // returns full browser version (e.g 65.0.3325.181 for Chrome)
  browserVersion: string; // returns browser major version (e.g 65 in Chrome or 9 in IE)
  browserName: string; // returns browser name
  mobileVendor: string; // returns mobile device vendor (e.g LG, iPhone etc)
  mobileModel: string; // returns mobile device model (e.g Nexus 5)
  engineName: string; // returns browser engine name (e.g Gecko for FF or Blink for Chrome)
  engineVersion: string; // returns engine version
  getUA: string; // returns user agent
  deviceType: string; // returns device type (e.g mobile or tablet)
  isIOS13: boolean; //	returns true/false if device is running on iOS13
  isIPhone13: boolean; //	returns true/false if device is iPhone and running on iOS13
  isIPad13: boolean; //	returns true/false if device is iPad and running on iOS13
  isIPod13: boolean; //	returns true/false if device is iPod and running on iOS13
  isElectron: boolean; //	returns true/false if running on Electron
  isEdgeChromium: boolean; //	returns true/false if browser is Edge Chromium
  isLegacyEdge: boolean; //	returns true if browser is Edge
  isWindows: boolean; //	returns true/false if os is Windows
  isMacOs: boolean; //	returns true/false if os is Mac OS
  deviceDetect: object; // return data object which includes all data about device (e.g version, engine, os etc.)
  OsTypes: typeof OsTypes; // return data object with os types
  BrowserTypes: typeof BrowserTypes;
}

export enum ScreenOrientation {
  Portrait = 'PORTRAIT',
  Landscape = 'LANDSCAPE'
}

export enum OperatingSystem {
  Android = 'Android',
  iOS = 'iOS',
  Windows = 'Windows',
  MacOS = 'MacOS'
}

export enum FormFactor {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE'
}

export default function usePlatform() {
  const [selectors] = useDeviceSelectors(window.navigator.userAgent) as [IDeviceSelectors, object];

  const { isMobile, browserName, browserVersion, isIOS, isAndroid, isBrowser, isMacOs, isWindows } = selectors;
  const OS = useMemo(() => {
    if (isIOS) return OperatingSystem.iOS;
    if (isAndroid) return OperatingSystem.Android;
    if (isMacOs) return OperatingSystem.MacOS;
    if (isWindows) return OperatingSystem.Windows;
    return null;
  }, [isAndroid, isIOS, isMacOs, isWindows]);

  const formFactor = useMemo(() => {
    if (isMobile) return FormFactor.Mobile;
    return FormFactor.Desktop;
  }, [isMobile]);

  return useMemo(
    () => ({
      OS,
      formFactor,
      isMobile,
      isWindows,
      isAndroid,
      isIOS,
      isMacOs,
      isApple: isMacOs || isIOS,
      isBrowser: isBrowser as unknown as boolean,
      browserName,
      browserVersion
    }),
    [OS, browserName, browserVersion, formFactor, isAndroid, isBrowser, isIOS, isMacOs, isMobile, isWindows]
  );
}
