import clsx from 'clsx';
import { createStyles } from '@zavy360/config/themes';
import type * as React from 'react';
import { useMemo } from '@zavy360/hooks/react';

const useStyles = createStyles({
  container: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    overflow: 'hidden',
    transform: 'translateX(-100%)',
    '-webkit-transform': 'translateX(-100%)'
  },

  slideIn: (config: ISlideInConfig) => ({
    animation: `${config.in === 'left' ? '$slideInLeft' : '$slideInRight'} 0.5s forwards`,
    '-webkit-animation': '$slideIn 0.5s forwards'
  }),

  slideOut: (config: ISlideInConfig) => ({
    animation: `${config.out === 'left' ? '$slideOutLeft' : '$slideOutRight'} 0.5s forwards`,
    '-webkit-animation': '$slideOut 0.5s forwards'
  }),

  '@keyframes slideInLeft': {
    '0%': { '-webkit-transform': 'translateX(-100%)', opacity: 0 },
    '50%': { '-webkit-transform': 'translateX(-50%)', opacity: 0 },
    '100%': { transform: 'translateX(0%)', opacity: 1 }
  },

  '@-webkit-keyframes slideInLeft': {
    '0%': { '-webkit-transform': 'translateX(-100%)', opacity: 0 },
    '50%': { '-webkit-transform': 'translateX(-50%)', opacity: 0 },
    '100%': { '-webkit-transform': 'translateX(0%)', opacity: 1 }
  },

  '@keyframes slideInRight': {
    '0%': { '-webkit-transform': 'translateX(100%)', opacity: 0 },
    '50%': { transform: 'translateX(50%)', opacity: 0 },
    '100%': { transform: 'translateX(0%)', opacity: 1 }
  },

  '@-webkit-keyframes slideInRight': {
    '0%': { '-webkit-transform': 'translateX(100%)', opacity: 0 },
    '50%': { '-webkit-transform': 'translateX(50%)', opacity: 0 },
    '100%': { '-webkit-transform': 'translateX(0%)', opacity: 1 }
  },

  '@keyframes slideOutLeft': {
    '0%': { transform: 'translateX(0%)', opacity: 1 },
    '25%': { transform: 'translateX(-25%)', opacity: 0 },
    '100%': { transform: 'translateX(-100%)', opacity: 0 }
  },

  '@-webkit-keyframes slideOutLeft': {
    '0%': { '-webkit-transform': 'translateX(0%)', opacity: 1 },
    '25%': { '-webkit-transform': 'translateX(-25%)', opacity: 0 },
    '100%': { '-webkit-transform': 'translateX(-100%)', opacity: 0 }
  },
  '@keyframes slideOutRight': {
    '0%': { transform: 'translateX(0%)', opacity: 1 },
    '50%': { transform: 'translateX(50%)', opacity: 0 },
    '100%': { transform: 'translateX(100%)', opacity: 0 }
  },

  '@-webkit-keyframes slideOutRight': {
    '0%': { '-webkit-transform': 'translateX(0%)', opacity: 1 },
    '50%': { '-webkit-transform': 'translateX(50%)', opacity: 0 },
    '100%': { '-webkit-transform': 'translateX(100%)', opacity: 0 }
  }
});

interface ISlideInConfig {
  out: 'left' | 'right';
  in: 'left' | 'right';
}

const DEFAULT_CONFIG: ISlideInConfig = {
  out: 'right',
  in: 'left'
};
export interface ISlideInAnimationProps extends React.HTMLProps<HTMLDivElement> {
  state?: 'in' | 'out';
  config?: ISlideInConfig;
}

export default function SlideIn(props: ISlideInAnimationProps) {
  const { state = 'in', config = DEFAULT_CONFIG, children, ...divProps } = props;
  const animationConfig = useMemo(
    () => ({
      ...DEFAULT_CONFIG,
      in: config?.in || DEFAULT_CONFIG.in,
      out: config?.out || DEFAULT_CONFIG.out
    }),
    [config?.in, config?.out]
  );
  const { className } = divProps;

  const classes = useStyles(animationConfig);
  return (
    <div
      {...divProps}
      className={clsx(
        classes.container,
        {
          [classes.slideIn]: state === 'in',
          [classes.slideOut]: state === 'out'
        },
        className
      )}
    >
      {children}
    </div>
  );
}
