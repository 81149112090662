import * as React from 'react';
import { useCallback } from './useCallback';
import useEffect from './useEffect';

export interface IStateCacheEngine {
  getItem(key: string): string;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

const NullEngine: IStateCacheEngine = {
  getItem: () => null,
  setItem: (key: string, value: string) => undefined,
  removeItem: (key: string) => undefined
};

export interface IExtendedUseStateOpts {
  engine?: IStateCacheEngine;
  key?: string;
}

export const DEFAULT_STATE_OPTS: IExtendedUseStateOpts = {
  engine: NullEngine,
  key: undefined
};

/**
 * Extends React.useState to allow caching in any compatible
 * adapter that implements .getItem and .setItem, e.g
 * localStorage, sessionStorage, or a custom adapter.
 * This can be extended further to support async storage
 * adapters, e.g. AsyncStorage in React Native, or anything
 * else that implements the same interface.
 */
function useState<T>(initialValue?: T, opts?: IExtendedUseStateOpts) {
  const { engine = NullEngine, key } = { ...DEFAULT_STATE_OPTS, ...opts };

  const getInitialValue = useCallback(() => {
    try {
      const storageEngineValue = engine.getItem(key);

      if (typeof storageEngineValue !== 'string') {
        engine.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      }
      return JSON.parse(storageEngineValue || 'null');
    } catch (e) {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  }, [engine, initialValue, key]);

  // We need to reset if the value changes for the key
  const stateValueKey = React.useRef(key);

  const [state, setState] = React.useState<T>(getInitialValue);

  const update = useCallback(
    (value: T) => {
      try {
        setState(value);
        if (value === undefined || value === null) {
          engine.removeItem(key);
        } else {
          const serializedState = JSON.stringify(value);
          engine.setItem(key, serializedState);
        }
      } catch (e) {
        console.error(e);
        // If user is in private mode or has storage restriction
        // sessionStorage can throw. Also JSON.stringify can throw.
      }
    },
    [key, engine]
  );

  // Reset if the key changes
  useEffect(() => {
    if (key !== stateValueKey.current) {
      stateValueKey.current = key;
      setState(getInitialValue());
    }
  }, [getInitialValue, key, update]);

  return [state, update] as const;
}

export default useState;
